import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        // Fetch profile data after authentication
        axios.get('https://api.r60.archnix.dev/api/profile', { withCredentials: true })
            .then(response => {
                setProfile(response.data.profile);
            })
            .catch(error => {
                console.error('Error fetching profile', error);
            });
    }, []);

    const handleLinkedInLogin = () => {
        window.location.href = 'https://api.r60.archnix.dev/auth/linkedin';  // Redirect to LinkedIn login
    };

    return (
        <div className="App">
            <h1>LinkedIn Authentication</h1>
            {!profile ? (
                <button onClick={handleLinkedInLogin}>Import from LinkedIn</button>
            ) : (
                <div>
                    <h2>Profile Info</h2>
                    <p>Name: {profile.localizedFirstName} {profile.localizedLastName}</p>
                    <p>Headline: {profile.headline}</p>
                </div>
            )}
        </div>
    );
}

export default App;